<template>
  <div>
    <div class="page-list-container">
      <div class="row mt-5">
        <div class="col-sm-12 text-left">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="font-weight-bold">Daftar Pengaduan</h4>

            <JsonExcel
                v-if="userData && isAdmin"
                :fetch="prepareReportData"
                type="xlsx" :name="getReportFileName"
                :before-finish="getReportList">
              <button class="btn btn-yellow rounded-pill px-3 font-weight-bold">
                <span><i class="fas fa-file-excel mr-2"></i></span>
                Download Excel
              </button>
            </JsonExcel>
          </div>
          <hr>
        </div>
      </div>
      <div class="mt-3 mb-5">
        <div class="row">
          <div class="col-sm-3">
            <div class="input-with-icon mb-3">
              <input type="text"
                     :value="query.search"
                     @input="handleTextInput"
                     v-on:keyup.enter="handleEnter"
                     class="form-control rounded-pill" placeholder="Cari">
            </div>
            <div
                v-if="userData"
                class="form-group mt-4 text-left">
              <div class="form-check">
                <input
                    v-model="myReport"
                    :value="true"
                    class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  Report Saya
                </label>
              </div>
              <hr>
            </div>
            <div class="mt-4 text-left">
              <DaterangeComponent @input="handleInput($event, 'updateAt')"
                                  :value="getDatetimeValue"/>
            </div>
            <div class="mt-4 text-left">
              <p class="text-custom-primary mb-4"><strong>Status</strong></p>
              <SelectStatusComponent
                  :isFilter="true"
                  @input="handleInput($event, 'status')"
                  :value="query.status" class="mb-4"/>
            </div>

            <div class="mt-4 text-left">
              <p class="text-custom-primary mb-4"><strong>Lokasi Aduan</strong></p>

              <SelectCityComponent
                  @input="handleInput($event, 'city')"
                  :value="query.city" :province-id="provinceIdPapua" class="mb-4"/>

              <SelectDistrictComponent
                  @input="handleInput($event, 'district')"
                  :value="query.district" :city-id="Number(query.city)" class="mb-4"
                  :class="{ 'opacity-5': !query.province }"
                  :key="renderKey"/>

              <SelectVillageComponent
                  @input="handleInput($event, 'village')"
                  :value="query.village" :district-id="Number(query.district)" class="mb-4"
                  :class="{ 'opacity-5': !query.district }"
              />
            </div>

            <div class="mt-4 text-left">
              <p class="text-custom-primary mb-4"><strong>Laporan</strong></p>
              <SelectReportCategoryComponent
                  @input="handleInput($event, 'category')"
                  :value="query.category" class="mb-4"/>
              <SelectReportTypeComponent
                  @input="handleInput($event, 'type')"
                  :value="query.type"
                  :category-id="Number(query.category)"
                  class="mb-4" :class="{ 'opacity-5': !query.category }"
                  :key="renderKey"/>
            </div>

            <div class="mt-4 text-left">
              <p class="text-custom-primary mb-4"><strong>Pelapor</strong></p>
              <SelectInstitutionComponent
                  :no-label="true"
                  @input="handleInput($event, 'institution')"
                  :value="query.institution" class="mb-4"/>
            </div>
          </div>

          <div v-if="!isLoading" class="col-sm-9">
            <ReportListRowComponent v-for="(item, index) in data" :item="item" :key="index"/>

            <div v-if="!data.length">
              Data not found.
            </div>

            <div class="w-100 text-center">
              <PaginationComponent
                  class="my-5"
                  :current-page="Number(page)"
                  :total-data="totalData"
                  :limit="limit"
                  @change-page="handleChangePage"
              />
            </div>
          </div>

          <div v-else class="col-sm-9">
            <div class="h-100 d-flex justify-content-center align-items-center">
              <LoadingComponent/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserHeaderComponent from "../../components/UserHeaderComponent";
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import CustomSelectComponent from "../../../components/input/CustomSelectComponent";
import ReportListRowComponent from "../../components/ReportListRowComponent";
import FooterComponent from "../../../components/general/FooterComponent";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {getReport} from "../../../service/ReportService";
import SelectProvinceComponent from "../../../components/input/SelectProvinceComponent";
import SelectCityComponent from "../../../components/input/SelectCityComponent";
import SelectDistrictComponent from "../../../components/input/SelectDistrictComponent";
import SelectReportCategoryComponent from "../../../components/input/SelectReportCategoryComponent";
import SelectReportTypeComponent from "../../../components/input/SelectReportTypeComponent";
import SelectInstitutionComponent from "../../../components/input/SelectInstitutionComponent";
import PaginationComponent from "../../../components/general/PaginationComponent";
import LoadingComponent from "../../../components/general/LoadingComponent";
import SelectStatusComponent from "../../../components/input/SelectStatusComponent";
import PaginationSimpleComponent from "../../../components/general/PaginationSimpleComponent";
import SelectVillageComponent from "../../../components/input/SelectVillageComponent";
import JsonExcel from "vue-json-excel"
import moment from "moment";
import DaterangeComponent from "../../../components/input/DaterangeComponent";

const PROVINCE_ID_PAPUA = 94

export default {
  name: "ReportListScreen",
  components: {
    DaterangeComponent,
    SelectVillageComponent,
    PaginationSimpleComponent,
    SelectStatusComponent,
    LoadingComponent,
    PaginationComponent,
    SelectInstitutionComponent,
    SelectReportTypeComponent,
    SelectReportCategoryComponent,
    SelectDistrictComponent,
    SelectCityComponent,
    SelectProvinceComponent,
    FooterComponent,
    ReportListRowComponent,
    CustomSelectComponent,
    CustomInputComponent,
    UserHeaderComponent,
    JsonExcel
  },
  data() {
    return {
      data: [],
      limit: 10,
      offset: 0,
      totalData: 0,
      isLoading: false,
      currentPage: 1,
      renderKey: 1,
      limitPage: 8,
      search: '',
      myReport: false,
      provinceIdPapua: PROVINCE_ID_PAPUA
    }
  },
  methods: {
    ...mapMutations('ReportStore', ['setReports', 'resetReport', "setOffset", "setSelectedReport"]),
    ...mapActions('NotificationStore', ['openNotification']),
    handleEnter() {
      this.handleInput(this.search, 'search')
    },
    handleTextInput(e) {
      this.search = e.target.value
    },
    handleInput(e, type) {
      const query = {...this.query}

      if (type === 'updateAt') {
        if (e.startDate && e.endDate) {
          query['updateAt'] = `${e.startDate}, ${e.endDate}`
        }
      } else {
        let value = e
        if (e && e.id) value = e.id
        query[type] = value
      }

      if (type === 'province') {
        delete query.city
        delete query.district
        delete query.village
      }

      if (type === 'city') {
        delete query.district
        delete query.village
      }

      if (type === 'district') {
        delete query.village
      }

      if (type === 'category') {
        delete query.type
      }

      query.page = 1

      this.setOffset(0)
      this.$router.push({
        name: 'ReportList',
        query
      }).catch(e => {
      })
    },
    async getReportList(isReport = false) {
      this.isLoading = true
      try {
        const parameter = {
          filter: {}
        }

        const {status, province, city, district, village, category, type, institution, user, updateAt} = this.query

        if (status) parameter.filter['where[status][id]'] = status
        if (province) parameter.filter['where[province][id]'] = province
        if (city) parameter.filter['where[regencies][id]'] = city
        if (district) parameter.filter['where[district][id]'] = district
        if (category) parameter.filter['where[reportCategory][id]'] = category
        if (type) parameter.filter['where[reportType][id]'] = type
        if (institution) parameter.filter['where[institusi][id]'] = institution
        if (village) parameter.filter['where[desa][id]'] = village
        if (user) parameter.filter['where[user][id]'] = this.userData.id
        if (updateAt) {
          let dates = updateAt.split(',')
          let urlParams = new URLSearchParams()
          let key = 'where[updateAt][between]'
          parameter.filter['where[updateAt][between]'] = dates
        }

        parameter.filter = {
          ...parameter.filter,
          ...{
            take: this.limitPage,
            skip: String(this.page) === String(1) ? this.offset : (this.page - 1) * this.limitPage
          }
        }

        if (isReport) {
          delete parameter.filter.take
          delete parameter.filter.skip
        }

        const res = await getReport(parameter)
        if (res && res.data) {
          this.data = res.data.data
          this.totalData = res.data.total
        }
      } catch (e) {
        const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
        await this.openNotification({
          message: message,
          type: 'error'
        })
      }
      this.isLoading = false
    },
    handleChangePage(page) {
      this.$router.push({
        name: 'ReportList',
        query: {
          ...this.query,
          ...{page}
        }
      }).catch(e => {
      })
    },
    async prepareReportData() {
      await this.getReportList(true)

      return this.formatDataExcel
    }
  },
  mounted() {
    this.getReportList()
    if (this.query && this.query.user) {
      this.myReport = this.query.user === 'true'
    }
  },
  computed: {
    ...mapGetters('UserStore', ['isAdmin']),
    ...mapState('UserStore', ['userData']),
    query() {
      return this.$route.query
    },
    page() {
      const {page} = this.query
      if (page) return page
      return 1
    },
    formatDataExcel() {
      moment.locale()

      return this.data.map(item => {
        return {
          id: item.id,
          mobile: item.mobile,
          institusi: item.institusiName,
          latitude: item.latitude,
          longitude: item.longitude,
          arah: item.direction?.name,
          jarak: item.distance?.name,
          lokasi: item.location,
          alamat: item.address,
          provinsi: item.province.name,
          kabupaten: item.regencies.name,
          distrik: item.district.name,
          kampung: item.villages.name,
          kategori: item.reportCategory.name,
          'sub kategori': item.reportType.name,
          deskripsi: item.description,
          catatan: item.notes,
          status: item.status.name,
          'tanggal update': item.updateAt ? moment(item.updateAt).locale('id').format('lll') : '-'
        }
      })
    },
    getReportFileName() {
      return `report-list-${moment().format('YYYYMMDD')}`
    },
    getDatetimeValue() {
      if (this.query.updateAt) {
        let datetime = this.query.updateAt.split(',')

        return {
          startDate: moment(datetime[0], ["MM-DD-YYYY", "YYYY-MM-DD"]),
          endDate: moment(datetime[1], ["MM-DD-YYYY", "YYYY-MM-DD"])
        }
      }
    }
  },
  watch: {
    filters: {
      handler() {
        this.currentPage = 1
        this.offset = 0
        this.getReportList()
      },
      deep: true
    },
    '$route.query': function () {
      this.getReportList()
    },
    myReport(val) {
      this.handleInput(val, 'user')
    }
  }
}
</script>

<style scoped lang="scss">
.page-list-container {
  width: 90%;
  margin: auto;
}
</style>
