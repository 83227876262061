<template>
	<div class="shadow-lg mt-5 pt-2 pb-2">
		<div class="footer-container">
			<div class="row">
				<div class="col-sm-6 text-left">
					<h5 class="font-weight-bold mb-3">Tentang Simtaru</h5>
					<p>
						SIMTARU 2.0 PAPUA dikembangkan dengan menggunakan aplikasi
						Open Source yang merupakan bagian dari Sistem Manajemen Informasi berbasis Spasial (SMIS).
						Aplikasi ini merupakan pengembangan dari IMS dikembangkan dari kegiatan PLUP
						Program Compact Hibah MCC yang dilaksanakan oleh Kemeterian PPN/Bappenas. Sajian informasi spasial
						merupakan kombinasi teknologi kartografi modern dengan metode-metode partisipatif.
					</p>
				</div>

				<div class="col-sm-6 text-left">
					<h5 class="font-weight-bold mb-3">Kontak</h5>
					<p>
						<strong>Dinas Komunikasi dan Informasi Provinsi Papua</strong><br>
						Jln. Soa Siu Dok 2 Bawah Jayapura Papua.
						(+62) 0967 524140-537324-537378.
						diskominfo@papua.go.id
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "FooterComponent"
}
</script>

<style scoped>
	.footer-container {
		width: 90%;
		margin: 30px auto;
	}
</style>