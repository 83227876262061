<template>
  <ModalComponent
      :is-show="true"
      :is-scrollable="true"
      :size="`modal-xl`"
      :is-header="true"
      :is-footer="true"
      :title="`Preview`"
      @close="$emit('close')"
  >
    <template v-slot:body>
      <div id="print-container" class="print-settings">
        <div style="display: flex; justify-content: space-between;">
          <div style="text-align: left; line-height: 1.4;">
            <h5 style="margin-bottom: 0; font-weight: bold">
              FORMULIR REKOMENDASI VERIFIKASI <br>
              LAPORAN INDIKASI PELANGGARAN PEMANFAATAN RUANG <br>
              PROVINSI PAPUA
            </h5>
          </div>

          <img src="../../../../assets/images/logo-kop-simtaru.png">
        </div>
        <hr style="border-top: 3px double #000">

        <div style="margin: 15px 0px">
          <table>
            <tr>
              <td style="width: 50px; padding: .5rem">1.</td>
              <td style="width: 280px; font-weight: bold; padding: .5rem">ID Pelaporan</td>
              <td style="padding: .5rem">: {{ data.id }}</td>
            </tr>
            <tr>
              <td style="padding: .5rem">2.</td>
              <td style="padding: .5rem">
                <span style="font-weight: bold">Tanggal Pelaporan</span> <br>
                <em>(dd/mm/yy)</em>
              </td>
              <td style="padding: .5rem">: {{ $helper.formatDate(data.createAt) }}</td>
            </tr>
            <tr>
              <td style="padding: .5rem">3.</td>
              <td style="font-weight: bold; padding: .5rem">Titik Koordinat</td>
              <td></td>
            </tr>
            <tr>
              <td>
              </td>
              <td colspan="2" style="border: 1px solid #ccc; padding: .5rem">
                X : {{ data.longitude }} &nbsp;&nbsp;&nbsp;
              </td>
              <td colspan="2" style="border: 1px solid #ccc; padding: .5rem">
                Y : {{ data.latitude }}
              </td>
              <td colspan="7"></td>
            </tr>
            <tr>
              <td style="padding: .5rem">4.</td>
              <td style=" padding: .5rem; font-weight: bold">Lokasi</td>
            </tr>
            <tr>
              <td>
              </td>
              <td style="padding: .5rem">
                <em>Kabupaten</em>
              </td>
              <td colspan="7" style="padding: .5rem">
                : {{ data.district.name }}
              </td>
            </tr>
            <tr>
              <td>
              </td>
              <td style="padding: .5rem">
                <em>Kecamatan</em>
              </td>
              <td colspan="7" style="padding: .5rem">
                : {{ data.regencies.name }}
              </td>
            </tr>
            <tr>
              <td>
              </td>
              <td style="padding: .5rem">
                <em>Kampung</em>
              </td>
              <td colspan="7" style="padding: .5rem">
                : {{ data.villages.name }}
              </td>
            </tr>

            <tr>
              <td style="padding: .5rem">5.</td>
              <td style="font-weight: bold; padding: .5rem">Dokumentasi</td>
            </tr>

            <tr v-for="(value, key) in images">
              <td
                  :key="index"
                  :colspan="item.empty ? 0 : 2"
                  :style="[ item.empty ? {} : {border: `1px solid #ccc`, padding: `.5rem`}]"
                  v-for="(item, index) in value">
                <img
                    v-if="!item.empty && item.url"
                    style="width: 100%"
                    :src="item.url" alt="">
              </td>
            </tr>

            <tr>
              <td style="padding: .5rem">6.</td>
              <td style="font-weight: bold; padding: .5rem" colspan="2">Tipologi Laporan Pelanggaran<br> Pemanfaatan Ruang</td>
              <td style="padding: .5rem" colspan="2">
                <ul style="list-style-type: none; padding: 0; width: 300px">
                  <li v-for="(item, index) in categoryOptions" :key="index">
                    <span><i class="far"
                             :class="data.reportCategory.id === item.id ? 'fa-check-square' : 'fa-square'"></i></span>
                    {{ item.name }}
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td style="padding: .5rem">7.</td>
              <td style="font-weight: bold; padding: .5rem" colspan="2">Deskripsi Indikasi <br> Pelanggaran Pemanfaatan
                Ruang
              </td>
              <td style="padding: .5rem">
                : {{ data.description }}
              </td>
            </tr>

            <tr>
              <td style="padding: .5rem">8.</td>
              <td style="font-weight: bold; padding: .5rem" colspan="2">Hasil Verifikasi Administrasi</td>
              <td style="padding: .5rem">
                <table>
                  <tr>
                    <td>
                      : <span><i class="far fa-check-square"></i></span> Lengkap
                    </td>
                    <td>
                      <span><i class="far fa-square"></i></span> Tidak Lengkap
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style="">9.</td>
              <td style="font-weight: bold" colspan="2">Hasil Verifikasi Spasial</td>
            </tr>
            <tr>
              <td></td>
              <td colspan="1" style="border: 1px solid #000; background-color: #F5F5F5; vertical-align: middle"
                  align="center">
                Deskripsi Verifikasi
              </td>
              <td colspan="2" style="border: 1px solid #000; background-color: #F5F5F5; vertical-align: middle"
                  align="center">Dokumentasi Verifikasi
              </td>
            </tr>
            <tr v-for="(item, index) in data.spatial" :key="index">
              <td></td>
              <td colspan="1" style="padding: 1rem; border: 1px solid #000; vertical-align: top">
                {{item.description }}
              </td>
              <td colspan="2" style="padding: 1rem; border: 1px solid #000; vertical-align: top; text-align: center;">
                <h6>{{ item.caption }}</h6>
                <img :src="item.file[0].url" style="width: 100%">
              </td>
            </tr>

            <tr>
              <td style="padding: .5rem">10.</td>
              <td style="font-weight: bold; padding: .5rem">Verifikasi Spasial</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">
                Hasil Verifikasi Spasial
              </td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                <span><i class="far" :class="data.indikasi ? 'fa-check-square' : 'fa-square'"></i></span> <strong>TERINDIKASI</strong>
                adanya pelanggaran pemanfaatan ruang <br>
                <span><i class="far" :class="!data.indikasi ? 'fa-check-square' : 'fa-square'"></i></span> <strong>TIDAK
                TERINDIKASI</strong> adanya pelanggaran pemanfaatan ruang
              </td>
            </tr>

            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Catatan</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ verifikasiDiterima ? verifikasiDiterima.notes : '' }}
              </td>
            </tr>

            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Oleh</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ verifikasiDiterima ? verifikasiDiterima.user.fullname : '' }}
              </td>
            </tr>

            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Tanggal</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ verifikasiDiterima ? $helper.formatDate(verifikasiDiterima.createAt) : '' }}
              </td>
            </tr>

            <tr>
              <td style="padding: .5rem">11</td>
              <td style="font-weight: bold; padding: .5rem">Rekomendasi Unit Kliring</td>

            </tr>

            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">
                Rekomendasi Unit Kliring
              </td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                <span><i class="far" :class="kliringDiterima ? 'fa-check-square' : 'fa-square'"></i></span> <strong>DITERUSKAN
                ke Pokja Pengendalian</strong><br>
                <span><i class="far" :class="kliringDitolak ? 'fa-check-square' : 'fa-square'"></i></span> <strong>DITOLAK</strong>
              </td>
            </tr>

            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Catatan</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ kliringDiterima ? kliringDiterima.notes : '' }}
                {{ kliringDitolak ? kliringDitolak.notes : '' }}
              </td>
            </tr>

            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Oleh</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ kliringDiterima ? kliringDiterima.user.fullname : '' }}
                {{ kliringDitolak ? kliringDitolak.user.fullname : '' }}
              </td>
            </tr>

            <tr>
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Tanggal</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ kliringDiterima ? $helper.formatDate(kliringDiterima.createAt) : '' }}
                {{ kliringDitolak ? $helper.formatDate(kliringDitolak.createAt) : '' }}
              </td>
            </tr>

            <tr v-if="diteruskanKeNasional || selesai">
              <td style="padding: .5rem">12</td>
              <td style="font-weight: bold; padding: .5rem">Rekomendasi Pokja Pengedalian</td>
            </tr>

            <tr v-if="diteruskanKeNasional || selesai">
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">
                Status Laporan
              </td>
              <td style="padding: .5rem; border: 1px solid #ccc" colspan="7">
                <span><i class="far" :class="diteruskanKeNasional ? 'fa-check-square' : 'fa-square'"></i></span> <strong>Laporan SELESAI dan DITERUSKAN KE NASIONAL</strong>
                <br>
                <span><i class="far" :class="selesai ? 'fa-check-square' : 'fa-square'"></i></span> <strong>Laporan SELESAI</strong>
              </td>
            </tr>

            <tr v-if="diteruskanKeNasional || selesai">
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Catatan</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ diteruskanKeNasional ? diteruskanKeNasional.notes : '' }}
                {{ selesai ? selesai.notes : '' }}
              </td>
            </tr>

            <tr v-if="diteruskanKeNasional || selesai">
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Oleh</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ diteruskanKeNasional ? diteruskanKeNasional.user.fullname : '' }}
                {{ selesai ? selesai.user.fullname : '' }}
              </td>
            </tr>

            <tr v-if="diteruskanKeNasional || selesai">
              <td></td>
              <td style="padding: .5rem; border: 1px solid #ccc">Tanggal</td>
              <td colspan="7" style="padding: .5rem; border: 1px solid #ccc">
                {{ diteruskanKeNasional ? $helper.formatDate(diteruskanKeNasional.createAt) : '' }}
                {{ selesai ? $helper.formatDate(selesai.createAt) : '' }}
              </td>
            </tr>
          </table>
          <hr class="my-5">
          <div class="text-left">
            <em>Disclaimer</em>
            <br>
            <p>
              Dokumen ini dibuat melalui portal www.simtaru.papua.go.id, dimana proses verifikasi dan validasi telah dilakukan secara elektronik oleh masing-masing pihak terkait.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center">
        <button @click="printDocument" class="btn btn-primary btn-sm rounded-pill px-3 font-weight-bold ml-3">
          <span><i class="fas fa-print mr-2"></i></span>
          Print Document
        </button>
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from "../../../../components/general/ModalComponent";
import {mapState, mapMutations} from "vuex";
import {getReportCategory} from "../../../../service/LocationService";

export default {
  name: "ModalReportSummaryPrintComponent",
  components: {ModalComponent},
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      categoryOptions: []
    }
  },
  mounted() {
    this.reportCategories()
  },
  computed: {
    ...mapState('LocationStore', ['reportCategory']),
    verificatedData() {
      return this.data.reportStatus.find((item) => {
        return item.status.id === 2
      })
    },
    reportStatus() {
      return this.data.reportStatus
    },
    verifikasiDiterima() {
      return this.getReportStatusById(4)
    },
    kliringDiterima() {
      return this.getReportStatusById(5)
    },
    kliringDitolak() {
      return this.getReportStatusById(6)
    },
    diteruskanKePokja() {
      return this.getReportStatusById(7)
    },
    diteruskanKeNasional() {
      return this.getReportStatusById(8)
    },
    selesai() {
      return this.getReportStatusById(9)
    },
    images() {

      const images = []
      let kotak = []
      this.data.images.forEach((v, k) => {
        if (!kotak[0]) kotak.push({empty: true})
        if (this.data.images.length > 2) {
          if (kotak.length < 3) {
            kotak.push(v)
          }
          if (kotak.length === 3) {
            images.push(kotak)
            kotak = []
          }
        } else {
          kotak.push({empty: true})
          kotak.push(v)
          kotak.push({empty: true})
          images.push(kotak)
          kotak = []
        }
      })
      return images
    }
  },
  methods: {
    ...mapMutations('LocationStore', ['setReportCategory']),
    getReportStatusById(id) {
      if (this.reportStatus && this.reportStatus.length) {
        return this.reportStatus.find((v) => {
          return v.status.id === id
        })
      }
      return null
    },
    async printDocument() {
      try {
        this.$htmlToPaper('print-container');
      } catch (e) {
      }
    },
    async reportCategories() {
      if (this.reportCategory.length) {
        this.categoryOptions = this.reportCategory
      } else {
        let res = await getReportCategory({})
        this.categoryOptions = res.data
        this.setReportCategory(res.data)
      }
    },
  }
}
</script>

<style scoped>
tr {
  text-align: left;
  height: 35px;
}

td {
  vertical-align: top;
}

.print-settings {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
</style>
