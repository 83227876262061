<template>
	<div class="auth-screen-container">
		<FadeTransitionComponent>
			<component :is="getCurrentRoute"></component>
		</FadeTransitionComponent>
	</div>
</template>

<script>
import UserLoginComponent from "../components/auth/UserLoginComponent";
import UserRegisterComponent from "../components/auth/UserRegisterComponent";
import FadeTransitionComponent from "../../components/FadeTransitionComponent";
import ActivateEmailComponent from "../components/auth/ActivateEmailComponent";
import ResetPasswordComponent from "../components/auth/ResetPasswordComponent";
import ConfirmResetPasswordComponent from "../components/auth/ConfirmResetPasswordComponent";
import UserRegisterSocialComponent from "../components/auth/UserRegisterSocialComponent";

const LOGIN_ROUTE = 'Login'
const REGISTER_ROUTE = 'Register'
const ACTIVATE_EMAIL_ROUTE = 'ActivateEmail'
const RESET_PASSWORD_ROUTE = 'ResetPassword'
const CONFIRM_RESET_PASSWORD_ROUTE = 'ConfirmResetPassword'
const USER_LOGIN_COMPONENT = 'UserLoginComponent'
const USER_REGISTER_COMPONENT = 'UserRegisterComponent'
const ACTIVATE_EMAIL_COMPONENT = 'ActivateEmailComponent'
const RESET_PASSWORD_COMPONENT = 'ResetPasswordComponent'
const CONFIRM_RESET_PASSWORD_COMPONENT = 'ConfirmResetPasswordComponent'
const REGISTER_SOCIAL = 'UserRegisterSocialComponent'

export default {
	name: "AuthScreen",
	components: {
		FadeTransitionComponent,
		UserLoginComponent,
		UserRegisterComponent,
		ActivateEmailComponent,
		ResetPasswordComponent,
		ConfirmResetPasswordComponent,
    UserRegisterSocialComponent
	},
	data() {
		return {

		}
	},
	computed: {
		getCurrentRoute() {
			switch (this.$route.name) {
				case LOGIN_ROUTE: return USER_LOGIN_COMPONENT;
					break;
				case REGISTER_ROUTE: return USER_REGISTER_COMPONENT;
					break;
				case ACTIVATE_EMAIL_ROUTE: return ACTIVATE_EMAIL_COMPONENT;
					break;
				case RESET_PASSWORD_ROUTE: return RESET_PASSWORD_COMPONENT;
					break;
				case CONFIRM_RESET_PASSWORD_ROUTE: return CONFIRM_RESET_PASSWORD_COMPONENT;
					break;
        case 'RegisterSocial': return REGISTER_SOCIAL;
          break;
				default: return USER_LOGIN_COMPONENT;
					break;
			}
		}
	}
}
</script>

<style scoped lang="scss">
	.auth-screen-container {
		height: 100vh;
		position: relative;
		background: url("../../assets/images/bg-auth.jpg") no-repeat center;
		background-size: cover;
	}
</style>
