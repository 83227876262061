<template>
	<div class="vh-100 w-50 mx-auto d-flex flex-column justify-content-center">
			<div v-if="!successRegister" class="card w-75 p-4 mx-auto bg-white-transparent shadow">
				<div class="card-body">
					<div class="row">
						<div class="col-sm-12 text-center mb-3">
							<DynamicLogoComponent />
						</div>

						<div class="col-sm-12 mb-3">
							<p class="font-weight-bold text-left">Register</p>

							<form @submit.prevent="doRegister">
								<div class="form-group">
									<CustomInputPillComponent v-model="registerData.username"
									                          :is-with-label="true"
									                          label="Nama Pengguna" />
								</div>

								<div class="form-group">
									<CustomInputPillComponent v-model="registerData.fullname"
									                          :is-with-label="true"
									                          label="Nama Lengkap" />
								</div>

								<div class="form-group">
									<CustomInputPillComponent v-model="registerData.email" :is-with-label="true" label="Email" />
								</div>

                <div class="form-group">
                  <CustomInputPillComponent v-model="registerData.mobile"
                                            type="number"
                                            :is-with-label="true"
                                            label="Nomor Telepon" />
                </div>

								<div class="form-group">
									<CustomInputPillComponent v-model="registerData.institusiName"
									                          :is-with-label="true"
									                          label="Organisasi / Institusi" />
								</div>

								<div class="form-group">
									<CustomInputPillComponent v-model="registerData.password"
									                          type="password"
									                          :is-with-label="true"
									                          label="Kata Sandi"
									                          :min-length="6" />
								</div>

								<div class="form-group">
									<CustomInputPillComponent v-model="registerData.rePassword"
									                          type="password"
									                          :is-with-label="true"
									                          label="Konfirmasi Kata Sandi"
									                          :min-length="6" />
								</div>

								<div class="form-group mt-3">
									<div class="form-row">
										<div class="col-sm-6 text-left">
											Sudah punya akun ? <br>
											<router-link class="text-primary" :to="{ name: 'Login' }"><strong>Masuk</strong></router-link>
										</div>
										<div class="col-sm-6 text-right">
											<button type="submit" class="btn btn-sm btn-custom-primary rounded-pill w-75">
												Daftar
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<div v-else class="card w-75 p-4 mx-auto bg-white-transparent shadow">
				<div class="card-body">
					<h4 class="text-custom-primary mb-3 font-weight-bold">Pendaftaran Berhasil</h4>

					<p class="mb-3">
						Pendaftaran anda akan diproses dan anda akan menerima email konfirmasi untuk masuk
					</p>

					<a :href="urlLogin" class="btn btn-custom-primary rounded-pill px-4">
						Ok
					</a>
				</div>
			</div>

			<div v-if="isLoading" class="loading-fullscreen">
				<LoadingComponent />
			</div>
	</div>
</template>

<script>
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import CustomInputPillComponent from "../../../components/input/CustomInputPillComponent";
import DynamicLogoComponent from "../../../components/general/DynamicLogoComponent";
import {sanitizePhoneNumber, validateEmail} from "../../../util/ValidateInput";
import {Register} from "../../../service/AuthService";
import FadeTransitionComponent from "../../../components/FadeTransitionComponent";
import SlideAnimationComponent from "../../../components/SlideAnimationComponent";
import LoadingComponent from "../../../components/general/LoadingComponent";

export default {
	name: "UserRegisterComponent",
	components: {
		LoadingComponent,
		SlideAnimationComponent,
		FadeTransitionComponent, DynamicLogoComponent, CustomInputPillComponent, CustomInputComponent},
	data() {
		return {
			registerData: {
				username: '',
				fullname: '',
				email: '',
        mobile: '',
				institusi: '',
				password: '',
				rePassword: '',
        ref: 'web'
			},
			successRegister: false,
			isLoading: false,
      isFormSimtaru: this.$route.query.from,
		}
	},
  computed: {
    urlLogin() {
      const {from} = this.$route.query
      if(from && from === 'simtaru') {
        return 'https://simtaru.papua.go.id/account/login/?next=/'
      }
      return '/login'
    },
  },
  methods: {
		async doRegister() {
			this.isLoading = true

			if (!this.validateEmail()) {
				this.$store.dispatch('NotificationStore/openNotification', {
					message: 'Email tidak valid',
					type: 'warning'
				})
				this.isLoading = false

				return false
			}

      if (this.isFormSimtaru) this.registerData.ref = 'simtaru'

			try {
        const payload = {...this.registerData}

				let res = await Register(payload)

				if (res) {
					this.successRegister = true

          setTimeout(() => {
            window.location.href = this.urlLogin
          }, 3000)
				}
			} catch (e) {
				let messages = e.response.data.message
				if (typeof messages === 'object') {
					messages = messages.join('<br>')
				}

				this.$store.dispatch('NotificationStore/openNotification', {
					message: messages,
					type: 'error'
				})
			}

			this.isLoading = false
		},
		validateEmail() {
			return validateEmail(this.registerData.email)
		}
	}
}
</script>

<style scoped lang="scss">
.login-container {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}
</style>
