const defaultParams = () => {
    return {id: 0, name: ''}
}
const defaultReport = () => {
    return {
        distance: defaultParams(),
        direction: defaultParams(),
        province: {
            id: 94,
            name: 'PAPUA'
        },
        city: defaultParams(),
        district: defaultParams(),
        villages: defaultParams(),
        reportCategory: defaultParams(),
        reportType: defaultParams(),
        institusi: defaultParams(),
        institusiName: '',
        address: '',
        latitude: '',
        longitude: '',
        location: '',
        uuid: '',
        images: [],
        description: ''
    }
}

const defaultUpdateState = () => {
    return {
        isUpdating: false,
        data: {
            longitude: '',
            latitude: '',
            location: ''
        },
        oldData: null
    }
}

const ReportStore = {
    namespaced: true,
    state: {
        data: [],
        limit: 10,
        offset: 0,
        selectedReport: null,
        isMarkerActive: true,
        temporaryMarker: [],
        report: defaultReport(),
        isShowInfo: false,
        statisticReport: {
            pelapor: 0,
            laporan: 0,
            ditangani: 0
        },
        totalData: 0,
        updateState: defaultUpdateState(),
        isShowRatingReportModal: false,
        reportUnratedData: null
    },
    getters: {
        reports(state) {
            if (!state.isMarkerActive) {
                if (state.updateState.isUpdating) {
                    return [...[state.updateState.oldData], ...state.temporaryMarker]
                }

                return state.temporaryMarker
            }
            return state.data
        }
    },
    mutations: {
        setReports(state, value) {
            if (value && value.data) {
                state.data = [...state.data, ...value.data]
                state.totalData = value.total
            } else {
                state.data = []
                state.totalData = 0
            }
        },
        resetReport(state) {
            state.offset = 0
            state.data = []
        },
        setOffset(state, value) {
            state.offset = value
        },
        setSelectedReport(state, value) {
            state.selectedReport = value
        },
        setMarkerActive(state, value) {
            state.isMarkerActive = value
        },
        setTemporaryMarker(state, value) {
            state.temporaryMarker = value
        },
        setReport(state, value) {
            state.report = value
        },
        setShowInfo(state, value) {
            state.isShowInfo = value
        },
        setStatisticReport(state, value) {
            state.statisticReport = value
        },
        resetReportData(state) {
            state.report = defaultReport()
        },
        setUpdateState(state, value) {
            state.updateState = value
        },
        resetUpdateState(state) {
            state.updateState = defaultUpdateState()
        },
        setUnratedReportData(state, value) {
            state.reportUnratedData = value
        },
        setIsShowRatingReportModal(state, value) {
            state.isShowRatingReportModal = value
        }
    },
    actions: {},
}

export default ReportStore
