<template>
  <div class="vh-100 d-flex align-items-center justify-content-center">
    <LoadingComponent />
  </div>
</template>
<script>
import LoadingComponent from "../../../components/general/LoadingComponent";
export default {
	name: 'LogoutScreen',
  components: {LoadingComponent},
  methods: {
		logout() {
			localStorage.clear()
			this.$store.commit('UserStore/setUserData', null)
      setTimeout(() => {
        window.location.href = `${window.location.origin}/login`
      }, 500)
		}
	},
	mounted() {
		this.logout()
	}
}
</script>
<style lang="scss" scoped></style>
