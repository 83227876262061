<template>
  <div
      v-if="!isLoading"
      class="h-100 position-relative">
    <GmapMap
        :center="defaultCoordinate"
        :zoom="7"
        ref="map"
        map-type-id="hybrid"
        style="width: 100%; height: 100%"
        :options="{ mapTypeControl: false, fullscreenControl: false, draggableCursor: 'cursor' }"
        @click="handleMapClick"
        :key="renderKey"
    >
      <GmapMarker
          v-if="markers.length"
          v-for="(item, index) in markers"
          :position="item.coordinate"
          :key="index"
          @click="toggleInfoWindow(item)"
          :icon="item.customIcon ? item.customIcon : require('../assets/images/map-marker-red.png')"
      ></GmapMarker>

      <GmapInfoWindow
          v-if="isShowInfo && !isEdit"
          :position="selectedReport.coordinate"
          :opened="isShowInfo"
          @closeclick="setShowInfo(false)"
      >
        <InfoWindowDetailComponent/>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>

<script>
import InfoWindowDetailComponent from "../views/components/dashboard/InfoWindowDetailComponent";
import GoogleMapService from "../service/GoogleMapService";
import {mapActions, mapMutations, mapState, mapGetters} from "vuex";
import {getReport} from "../service/ReportService";

const STATUS_NEW = [10, 1]
const STATUS_DONE = [8, 9, 6]
const STATUS_VERIFICATED = [2, 4]
const STATUS_ON_GOING = [5, 7]

export default {
  name: 'GoogleMapsComponent',
  components: {InfoWindowDetailComponent},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPlace: null,
      google: null,
      places: [],
      infoWindowContext: null,
      lastMarker: false,
      temporaryMarker: null,
      renderKey: 1,
      isLoading: true
    }
  },
  watch: {
    '$route.query': function (val) {
      this.getReportList()
    },
    activeLayer(val) {
      this.triggerMapWms(val)
    }
  },
  computed: {
    ...mapState('ReportStore', ['offset', 'limit', 'selectedReport', 'isMarkerActive', 'isShowInfo', 'updateState']),
    ...mapGetters('ReportStore', ['reports']),
    ...mapState('MapsStore', ['defaultCoordinate', 'isAllowCreateMarker', 'activeLayer']),
    markers() {
      const data = []
      if (this.reports && this.reports.length) {
        this.reports.map((v) => {
          if (v) {
            v.coordinate = {
              lat: v.latitude,
              lng: v.longitude
            }

            if (!this.updateState.isUpdating) {
              let iconUrl = ''

              if (v.status) {
                if (STATUS_NEW.includes(v.status.id)) {
                  iconUrl = require('../assets/images/map-marker-yellow.png')
                } else if (STATUS_VERIFICATED.includes(v.status.id)) {
                  iconUrl = require('../assets/images/map-marker-red.png')
                } else if (STATUS_ON_GOING.includes(v.status.id)) {
                  iconUrl = require('../assets/images/map-marker-blue.png')
                } else if (STATUS_DONE.includes(v.status.id)) {
                  iconUrl = require('../assets/images/map-marker-green.png')
                } else {
                  iconUrl = require('../assets/images/map-marker-red.png')
                }
              } else {
                iconUrl = require('../assets/images/map-marker-red.png')
              }

              v.customIcon = {
                url: iconUrl ? iconUrl : ''
              }
            }
            data.push(v)
          }
        })
      }

      return data
    },
    query() {
      return this.$route.query
    }
  },
  async mounted() {
    await this.getReportList()
    if (this.activeLayer) this.triggerMapWms(this.activeLayer)

    setTimeout(() => {
      this.isLoading = false
    }, 500)
  },
  methods: {
    ...mapMutations('ReportStore', ['setReports', 'resetReport', "setOffset", "setSelectedReport", "setTemporaryMarker", "setShowInfo"]),
    ...mapActions('NotificationStore', ['openNotification']),
    ...mapMutations('MapsStore', ['setDefaultCoordinate', 'setContext']),
    toggleInfoWindow(context) {
      this.infoWindowContext = {...context}
      this.setSelectedReport(context)
      this.setShowInfo(true)
    },
    async getReportList() {
      this.isLoading = true
      try {
        const parameter = {
          filter: {}
        }

        this.resetReport()
        const {categoryId} = this.query
        if (categoryId) {
          parameter.filter['where[reportCategory][id]'] = categoryId
        }

        parameter.filter = {
          ...parameter.filter,
          ...{
            take: this.limit,
            skip: this.offset
          }
        }

        const res = await getReport(parameter)

        if (res && res.data) {
          this.setOffset(this.offset + this.limit)
          this.setReports(res.data)
        }
      } catch (e) {
        await this.openNotification({
          message: e.response.data.message,
          type: 'error'
        })
      }
      this.isLoading = false
    },
    async handleMapClick(e) {
      if (this.isAllowCreateMarker) {
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()

        const response = await this.getLocation({
          lat,
          lng
        })
        const currentContext = response.data.results[0]

        let temporaryMarker = Array.of({
          latitude: lat,
          longitude: lng,
        })

        let iconUrl = ''
        if (this.updateState.isUpdating) {
          iconUrl = require('../assets/images/map-marker-green.png')
        } else {
          iconUrl = require('../assets/images/map-marker-red.png')
        }

        temporaryMarker[0].customIcon = {
          url: iconUrl
        }

        this.setTemporaryMarker(temporaryMarker)
        this.setContext(currentContext)
      }
    },
    async getLocation(position) {
      return GoogleMapService.reverseGeoCoding(position.lat, position.lng)
    },
    triggerMapWms(activeLayer) {
      setTimeout(() => {
        this.$refs.map.$mapPromise.then((map) => {
          if (activeLayer) {

            map.overlayMapTypes.pop();
            var censusLayer = new google.maps.ImageMapType({
              getTileUrl: (coord, zoom) => {
                // Compose URL for overlay tile

                var s = Math.pow(2, zoom);
                var twidth = 256;
                var theight = 256;

                //latlng bounds of the 4 corners of the google tile
                //Note the coord passed in represents the top left hand (NW) corner of the tile.
                var gBl = map.getProjection().fromPointToLatLng(
                    new google.maps.Point(coord.x * twidth / s, (coord.y + 1) * theight / s)); // bottom left / SW
                var gTr = map.getProjection().fromPointToLatLng(
                    new google.maps.Point((coord.x + 1) * twidth / s, coord.y * theight / s)); // top right / NE

                // Bounding box coords for tile in WMS pre-1.3 format (x,y)
                var bbox = gBl.lng() + "," + gBl.lat() + "," + gTr.lng() + "," + gTr.lat();

                //base WMS URL
                var url = 'https://simtaru.papua.go.id/geoserver/geonode/wms';

                url += "?service=WMS"; //WMS service
                url += "&version=1.1.0"; //WMS version
                url += "&request=GetMap"; //WMS operation
                url += `&layers=${activeLayer.value}`; //WMS layers to draw
                url += "&styles="; //use default style
                url += "&format=image/png"; //image format
                url += "&TRANSPARENT=TRUE"; //only draw areas where we have data
                url += "&srs=EPSG:4326"; //projection WGS84
                url += "&bbox=" + bbox; //set bounding box for tile
                url += "&width=256"; //tile size used by google
                url += "&height=256";
                //url += "&tiled=true";
                return url; //return WMS URL for the tile */
              }, //getTileURL

              tileSize: new google.maps.Size(256, 256),
              opacity: 0.85,
              isPng: true
            });

            map.overlayMapTypes.push(censusLayer);
          } else {
            map.overlayMapTypes.clear()
          }
        })
      }, 1000)
    }
  },
  beforeDestroy() {
    this.setShowInfo(false)
  }
}
</script>

<style lang="scss">

</style>
