<template>
	<div
		class="bg-user-primary header-container container-fluid"
		:class="[isAdmin ? 'admin' : '']"
	>
		<div class="row">
			<div class="col-sm-2 bg-white shadow align-self-stretch">
				<a href="https://simtaru.papua.go.id/">
					<img src="../../assets/images/SimteruLogo.png" class="img-fluid" alt="Logo"/>
				</a>
			</div>
			<div class="col-sm-10 d-flex align-items-center">
				<h4 class="text-yellow"><strong>LAPORTARUNG</strong></h4>

        <div class="ml-auto position-relative align-items-end justify-content-end">
          <a @click="handleBeranda()" class="px-3 cursor-pointer">BERANDA</a>
          <a @click.prevent="redirectToDashboard" class="px-3 cursor-pointer">BUAT PENGADUAN</a>
          <router-link :to="{ name: 'ReportList' }" class="px-3">DAFTAR PENGADUAN</router-link>
          <router-link
              v-if="isSuperAdmin"
              to="/users" class="px-3 cursor-pointer">
            MANAJEMEN PENGGUNA
          </router-link>
          <UserProfileModal v-if="userData"/>
          <router-link v-if="!userData" :to="{ name: 'Login' }" class="px-3">MASUK</router-link>
          <ButtonNotificationComponent v-if="userData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"
import ModalComponent from "../../components/general/ModalComponent";
import UserProfileModal from "./dashboard/UserProfileModal";
import ButtonNotificationComponent from "./dashboard/ButtonNotificationComponent";

export default {
  name: "UserHeaderComponent",
  components: {ButtonNotificationComponent, UserProfileModal, ModalComponent},
  computed: {
    ...mapState('UserStore', ['userData']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdmin'])
  },
  methods: {
    handleBeranda() {
      this.$store.commit('SidebarStore/setIsCreateReport', false)
      this.$store.commit('SidebarStore/setIsShowSidebar', true)
      this.$store.commit('ReportStore/setMarkerActive', true)
      this.$store.commit('SidebarStore/setIsShowReportList', true)
      this.$router.push({
        name: 'Dashboard'
      }).catch(() => {})
    },
    redirectToDashboard() {
      if(this.userData) {
        this.$store.commit('SidebarStore/setIsCreateReport', true)
        this.$store.commit('SidebarStore/setIsShowSidebar', true)
        this.$store.commit('ReportStore/setMarkerActive', false)
        this.$store.commit('SidebarStore/setIsShowReportList', false)
        this.$router.replace({name: 'Dashboard', params: {action: 'createNewReport'}}).catch((e) => {
        })
      }else {
        this.$router.push({
          name: 'Login'
        }).catch(() => {})
      }

    },
  }
}
</script>

<style scoped lang="scss">
a {
	text-decoration: none;
	display: inline-block;
	color: white;
	font-size: 0.9em;

	&:hover {
		color: whitesmoke;
		text-decoration: none;
	}
}

h4 {
	font-size: 1.3em;
	margin-bottom: 0;
}

.header-container {
	position: relative;
}

.bg-user-primary {
	&.admin {
		background-color: #3B3B3B !important;
	}
}
</style>
