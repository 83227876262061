<template>
	<div class="app-container container-fluid py-5">
		<form @submit.prevent="handleSubmit">
			<div class="row">
				<div class="col-6 text-left">
					<h4 class="font-weight-bold m-0">
						Tambah Admin
					</h4>
				</div>
				<div class="col-6 text-right">
					<button
						v-if="!isLoading"
						class="btn btn-yellow btn-sm btn-primary rounded-pill px-3 font-weight-bold">
						<i class="fa fa-save mr-3"></i> Simpan
					</button>
				</div>
			</div>

			<hr>

			<div class="row">
				<div class="col-6 text-left">
					<div class="form-group text-blue font-weight-bold">
						DATA PROFILE
					</div>
					<div class="row">
						<div class="col-8">
							<div class="form-group">
								<CustomInputComponent
									@input="data.username = $event"
									:value="data.username"
									:read-only="false"
									:isRequired="true"
									placeholder="Nama Pengguna"/>
							</div>
							<div class="form-group">
								<CustomInputComponent
									@input="data.fullname = $event"
									:value="data.fullname"
									:read-only="false"
									:isRequired="true"
									placeholder="Nama Lengkap"/>
							</div>
							<div class="form-group">
								<CustomInputComponent
									@input="data.email = $event"
									:value="data.email"
									:read-only="false"
									:isRequired="true"
									:type="`email`"
									placeholder="Email"/>
							</div>
						</div>
						<div class="col-4">
							<UserAvatarComponent
								:image="data.avatar"
								@uploaded="data.avatar = $event"
							/>
						</div>
					</div>
					<div class="form-group">
						<CustomInputComponent
							@input="data.mobile = $event"
							:value="data.mobile"
							:read-only="false"
							:is-required="true"
							:type="`number`"
							placeholder="Nomor Ponsel"/>
					</div>
					<div class="form-group">
						<SelectInstitutionComponent
							@input="data.institusi = $event"
							:value="data.institusi && data.institusi.id ? data.institusi.id : ''"
						/>
					</div>
					<div class="form-group">
						<CustomInputComponent
							@input="data.institusiName = $event"
							:value="data.institusiName"
							:read-only="false"
							:is-required="true"
							:type="`text`"
							placeholder="Nama Instansi"/>
					</div>
					<div class="form-group">
						<CustomInputComponent
							@input="data.address = $event"
							:value="data.address"
							:read-only="false"
							:is-required="true"
							:type="`text`"
							placeholder="Alamat Instansi"/>
					</div>
					<div class="mt-5">
						<div class="form-group text-blue font-weight-bold">
							KATA SANDI
						</div>
						<div class="form-group">
							<CustomInputComponent
								@input="data.password = $event"
								:value="data.password"
								:read-only="false"
								:is-required="!existingId"
								:type="`password`"
								placeholder="Kata Sandi Baru"/>
						</div>
						<div class="form-group">
							<CustomInputComponent
								@input="confirmPassword = $event"
								:value="confirmPassword"
								:read-only="false"
								:is-required="!existingId"
								:type="`password`"
								placeholder="Konfirmasi kata sandi baru"/>
						</div>
					</div>
				</div>
				<div class="col-6 text-left">
					<div class="form-group text-blue font-weight-bold">
						KEWENANGAN
					</div>
					<div class="form-group">
						<SelectRolesComponent
							@input="data.role = $event"
							:value="data.role && data.role.id ? data.role.id : ''"
						/>
					</div>
					<div class="form-group">
						<CustomSelectComponent
							@input="data.status = $event"
							:value="data.status"
							:options="statusOptions" placeholder="Status"/>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import UserAvatarComponent from "../profile/components/UserAvatarComponent";
import SelectInstitutionComponent from "../../../components/input/SelectInstitutionComponent";
import CustomSelectComponent from "../../../components/input/CustomSelectComponent";
import SelectRolesComponent from "../../../components/input/SelectRolesComponent";
import {createUserAdmin} from "../../../service/AuthService";
import {getUserById} from "../../../service/AuthService";
import {updateUser} from "../../../service/AuthService";

const defaultData = () => {
	return {
		username: '',
		email: '',
		mobile: '',
		fullname: '',
		password: '',
		rePassword: '',
		institusiName: '',
		role: {
			id: '',
			name: ''
		},
		status: '',
		avatar: null
	}
}

export default {
	name: 'UserAddScreen',
	components: {
		SelectRolesComponent,
		CustomSelectComponent, SelectInstitutionComponent, UserAvatarComponent, CustomInputComponent
	},
	data() {
		return {
			data: defaultData(),
			confirmPassword: '',
			isLoading: false,
			existingId: null
		}
	},
	computed: {
		statusOptions() {
			return [
				{
					id: 'active',
					name: 'Active'
				},
				{
					id: 'inactive',
					name: 'Inactive'
				}
			]
		},
    isEdit() {
      return this.$route.params.id
    }
	},
	methods: {
		async handleSubmit() {
			this.isLoading = true
			try {
				const params = {...this.data}

				if(params.password) {
					if(params.password.length < 6) throw new Error('Kata Sandi Minimal 6 karakter')
					if(params.password !== this.confirmPassword) throw new Error('Konfirmasi kata sandi tidak sesuai')
					if(!this.$helper.validateEmail(params.email)) throw new Error('Email tidak valid')
					if(!this.$helper.validateUsername(params.username)) throw new Error('Nama Pengguna tidak valid, gunakan alphanumeric dan _')
					params.rePassword = this.confirmPassword
				}else {
          delete params.password
        }

        params.ref = 'web'
				params.mobile = Number(this.$helper.sanitizePhoneNumber(params.mobile))

				if (!this.existingId) {
					await createUserAdmin(params)
					this.$helper.errorNotification('Tambah User Succeess', 'success')
				} else {
					await updateUser(params)
					this.$helper.errorNotification('Update User Succeess', 'success')
				}

				this.data = defaultData()
				await this.$router.push({
					name: 'UserList'
				})
			}catch (e) {
				const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
				this.$helper.errorNotification(message)
			}
			this.isLoading = false
		},
		async getUser(id) {
			this.isLoading = true

			try {
				const res = await getUserById(id)

				if (res && res.data) {
					this.data = res.data
				}
			} catch (e) {
				this.$helper.errorNotification(e.response.data.message)
			}

			this.isLoading = false
		}
	},
	mounted() {
		let id = this.$route.params.id

		if (id) {
			this.existingId = id
			this.getUser(id)
		}
	}
}
</script>
<style lang="scss" scoped></style>
