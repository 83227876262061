import { render, staticRenderFns } from "./InfoWindowDetailComponent.vue?vue&type=template&id=81a54760&scoped=true&"
import script from "./InfoWindowDetailComponent.vue?vue&type=script&lang=js&"
export * from "./InfoWindowDetailComponent.vue?vue&type=script&lang=js&"
import style0 from "./InfoWindowDetailComponent.vue?vue&type=style&index=0&id=81a54760&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a54760",
  null
  
)

export default component.exports